import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Container, FormContainer, NarrowContainer } from '../../components/Container';
import { Title } from '../../components/Title';
import { Divider } from '../../components/Divider';
import { SubmitButton, BackButton, SwitchButton } from '../../components/Button';
import { TextInput, TextArea, CurrencyInput } from '../../components/TextInput';
import { SelectBox } from '../../components/SelectBox';
import StoresAPI from '../../api/StoresAPI';
import OrdersAPI from '../../api/OrdersAPI';
import UsersAPI from '../../api/UsersAPI';
import { Notes } from '../../components/Notes';

class OrderPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            id: props.match.params.id,
            userLevel: '',
            order_number: '',
            customerFirstName: '',
            customerLastName: '',
            email: '',
            created_at: moment(),
            shippingFirstName: '',
            shippingLastName: '',
            shippingLine1: '',
            shippingLine2: '',
            shippingTown: '',
            shippingCounty: '',
            shippingPostCode: '',
            shopifyId: '',
            total_price: 0,
            line_items: [],
            fulfillment_status: props.match.params.status,
            original_fulfillment_status: '',
            trackingCode: '',
            trackingUrl: '',
            fulfilmentMethod: '',
            errTrackingCode: '',
            errTrackingUrl: '',
            errTrackingCompany:'',
            collectionCode: '',
            fraudRisk: '',
            resent: '',
            pendingTransactions: 0,
            blockPayment: false,
            telephone: '',
            fulfillOptionDisable: false,
            selectOptions:[],
            collectionOptionDisable: false,
            selectCollectOptions:[],
            risk_level:'',
            risk_status:'',
            multiProducts:'',
            selectPendingOptions:[],
            selectFulfilledOptions:[],
            selectTrackingCompanyOptions:[],
            selectTrackingCompanys:[],
            selectTrackingCompanyURLs:[],
            selectPartialRefundOptions:[],
            selectRefundOptions:[],
            selectPendingCollectOptions:[],
            selectCollectedOptions:[],
            formTitle:'',
            mainTitle:'',
            visibility: false,
            items: [],
            errorTrackingCodes:[],
            errorTrackingCompanys:[],
            searchValues:this.props.location.state,
        
            
        };
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.approve = this.approve.bind(this);
        this.decline = this.decline.bind(this);
        this.sendMail = this.sendMail.bind(this);
        this.toggleBlock = this.toggleBlock.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        //this.loadCollectionOptions = this.loadCollectionOptions.bind(this);
    }

    componentDidMount() {

        UsersAPI.getUserData((data) => {
            this.setState({
                userLevel: data.userLevel,
            });
        })

        if (this.state.id !== 'new') {
            this.loadOrder();
            this.loadOptions();
           
        }
    }

    loadOrder() {
        OrdersAPI.getOrder(this.state.id, (data) => {
           this.setState({
                order_number: data.order_number,
                customerFirstName: data.customerFirstName,
                customerLastName: data.customerLastName,
                email: data.email,
                telephone: data.telephone,
                created_at: data.created_at,
                shippingFirstName: data.shippingFirstName,
                shippingLastName: data.shippingLastName,
                shippingLine1: data.shippingLine1,
                shippingLine2: data.shippingLine2,
                shippingTown: data.shippingTown,
                shippingCounty: data.shippingCounty,
                shippingPostCode: data.shippingPostCode,
                shopifyId: data.shopifyId,
                total_price: data.total_price,
                line_items: data.line_items,
                fulfillment_status: data.fulfillment_status,
                original_fulfillment_status: data.fulfillment_status,
                trackingCode: data.trackingCode,
                trackingUrl: data.trackingUrl,
                fulfilmentMethod: data.fulfilmentMethod,
                collectionCode: data.collectionCode,
                fraudRisk: data.fraudRisk,
                pendingTransactions: data.pendingTransactions,
                blockPayment: data.blockPayment,
                risk_level: data.risk_level,
                risk_score: data.risk_score,
                multiProducts:data.multiProducts,
            });
        })
        this.setState({
            errorTrackingCodes: [],
            errorTrackingCompanys: []
        })
        
        
    }
    loadOptions(){
        
        this.setState({
            fulfillOptionDisable: false,
            selectPartialRefundOptions:[{
                value: 'partial-refund',
                label: 'Partially Refunded'
            },
            {
                value: 'refunded',
                label: 'Refunded'
            }]

        })
      
        this.setState({
            fulfillOptionDisable: true,
            selectRefundOptions:[
            {
                value: 'refunded',
                label: 'Refunded'
            }]

        })
        this.setState({
            fulfillOptionDisable: true,
            selectFulfilledOptions:[
            {
                value: 'fulfilled',
                label: 'Fulfilled'
            }]

        })
        
        this.setState({
            fulfillOptionDisable: false,
            selectPendingOptions:[
            {
                value: '',
                label: 'Select...',
            }, {
                value: 'null',
                label: 'Pending',
            }, {
                value: 'fulfilled',
                label: 'Fulfilled',
            }]

        })
        this.setState({
            fulfillOptionDisable: false,
            selectPendingCollectOptions:[
            {
                value: '',
                label: 'Select...',
            }, {
                value: 'ready',
                label: 'Ready for Collection',
            }, {
                value: 'collected',
                label: 'Collected',
            }]

        })
        this.setState({
            fulfillOptionDisable: true,
            selectCollectedOptions:[
            {
                value: 'collected',
                label: 'Collected',
            }
           ]

        })
        this.setState({
            selectTrackingCompanyOptions:[
            {
                value: '',
                label: 'Select...',
            },
            {
                value: 'DHL',
                label: 'DHL',
            },
            {
                value: 'DPD',
                label: 'DPD',
            },
            {
                value: 'Royal Mail',
                label: 'Royal Mail',
            },
            {
                value: 'Parcelforce',
                label: 'Parcelforce',
            },
            {
                value: 'APC Overnight',
                label: 'APC Overnight',
            },
            {
                value: 'EVRi',
                label: 'EVRi',
            },
            {
                value: 'Fedex',
                label: 'Fedex',
            },
            {
                value: 'UPS',
                label: 'UPS',
            },
            {
                value: 'Yodel',
                label: 'Yodel',
            }
            
           ]

        })
        this.setState({
            selectTrackingCompanys:["DHL","DPD","Royal Mail","Parcelforce","APC Overnight","EVRi","Fedex","UPS","Yodel"]
        })
        this.setState({
            selectTrackingCompanyURLs:[
                "https://www.dhl.com/in-en/home/tracking/tracking-parcel.html?submit=1&tracking-id=<tracking_number>",
                "https://track.dpd.co.uk/search?reference=",
                " https://www.royalmail.com/track-your-item#/tracking-results/<tracking_number>",
                "https://www.parcelforce.com/track-trace",
                "https://apc-overnight.com/receiving-a-parcel/tracking",
                "https://www.evri.com/track-a-parcel",
                "https://www.fedex.com/fedextrack?action=track&trackingnumber=<tracking_number>",
                "https://www.ups.com/track?loc=en_US&tracknum=<tracking_number>",
                "https://www.yodel.co.uk/track",
            
           ]

        })
        this.state.formTitle = 'Fulfillment Details';
        
    }
    loadCollectionOptions(){
        if (this.state.fulfillment_status === 'collected' ){
            this.setState({
                collectionOptionDisable: true,
                selectCollectOptions:[{
                    value: 'collected',
                    label: 'Collected',
                }],
            })
        }else if(this.state.fulfillment_status === 'refunded') {
            this.setState({
                collectionOptionDisable: true,
                selectCollectOptions:[{
                    value: 'refunded',
                    label: 'Refunded',
                }],
            })
        }else if(this.state.fulfillment_status === 'partial-refund'){
            this.setState({
                collectionOptionDisable: false,
                selectCollectOptions:[{
                    value: 'partial-refund',
                    label: 'Partially Refunded'
                },
                {
                    value: 'refunded',
                    label: 'Refunded'
                }
            ],
            })
            
        }else {
            this.setState({
                collectionOptionDisable : false,
                selectCollectOptions :[{
                    value: '',
                    label: 'Select...',
                }, {
                    value: 'ready',
                    label: 'Ready for Collection',
                }, {
                    value: 'collected',
                    label: 'Collected',
                }],
            })
           
        }
    }
    onChange(name, value) {
        this.setState({
            [name]: value,
            errTrackingUrl: '',
            errTrackingCode: '',
            errTrackingCompany:'',
            errFulfillmentStatus: '',
        });
    }

    toggleBlock() {
        OrdersAPI.blockPayments(this.state.id, (data) => {
            this.loadOrder();
            
        });
    }

    save() {

        var _ok = true;
        const lineItems = [...this.state.line_items];
        let index =0;
        lineItems.forEach(item => {
          /*if(typeof item.fulfillment_status === 'undefined'){
            _ok = false;
            this.setState({
                errFulfillmentStatus: 'Please select a status'
            });
          }*/
          if (this.state.fulfilmentMethod === 'delivery') {
                 
                if(item.fulfillment_status === 'fulfilled'){
                    if(item.trackingCode === 'undefined' || item.trackingCode===null || item.trackingCode==='' ){
                        _ok = false;
                        this.state.errorTrackingCodes[index]= 'Please enter tracking code';
                            this.setState({
                                errTrackingCode: 'Please enter tracking code',
                                
                            })
                    }
                    if(item.trackingCompany === 'undefined' || item.trackingCompany===null || item.trackingCompany==='' ){
                        _ok = false;
                        this.state.errorTrackingCompanys[index]='Please select tracking company'
                            this.setState({
                                errTrackingCompany: 'Please select tracking company'
                            })
                    }
                    
                }
                if(typeof item.trackingUrl === 'undefined'){
                    _ok = false;
                        this.setState({
                            errTrackingUrl: 'Please enter tracking UL'
                        })
                }
            }
            index++;
        });
        if (_ok) {

            let payload = {
                line_items: this.state.line_items,
            }
           
            OrdersAPI.updOrder(this.state.id, payload, (data) => {
              //  OrdersAPI.updCollectionReady(this.state.id, this.state.fulfillment_status, (data) => {
                    this.props.history.push(`/orders`);
             //})
            });

        }

    }

    approve() {
        let payload = {
            fraudRisk: "accept"
        };
        OrdersAPI.updOrderFraud(this.state.id, payload, (data) => {
            this.props.history.push(`/orders/${this.state.id}`);
        })
    }

    decline() {
        let payload = {
            fraudRisk: "declined"
        };
        OrdersAPI.updOrderFraud(this.state.id, payload, (data) => {
            this.props.history.push(`/fraud`);
        })
    }

    cancel() {
        this.props.history.push(this.state.fraudRisk === 'cancel' ? '/fraud' : '/orders');
    }

    sendMail(type) {
        OrdersAPI.sendOrderMail(this.state.id, type, (data) => {
            this.setState({
                resent: type
            });
        });
    }
    updateFulfillmentStatus = (e,value, index) => {
        const lineItems = [...this.state.line_items];
        lineItems[index].fulfillment_status = value
        this.setState({ lineItems });
    }
    updateTrackingCode = (e, value, index) => {
        const lineItems = [...this.state.line_items];
        lineItems[index].trackingCode = value
        if(lineItems[index].trackingCompany !==null && lineItems[index].trackingCompany!==''){
            var tempTrackCompany = lineItems[index].trackingCompany;
            var selectionOptionIndex = this.state.selectTrackingCompanys.indexOf(tempTrackCompany);
            var tempTrackUrl= this.state.selectTrackingCompanyURLs[selectionOptionIndex];
            var newTrackUrl = tempTrackUrl.replace("<tracking_number>",lineItems[index].trackingCode);
            lineItems[index].trackingUrl = newTrackUrl

        }
        this.setState({ lineItems });
    }
    updateTrackingUrl = (e, value,index) => {
        const lineItems = [...this.state.line_items];
        lineItems[index].trackingCompany = value;
        if(value!==''){
            var selectionOptionIndex = this.state.selectTrackingCompanys.indexOf(value);
            var tempTrackUrl= this.state.selectTrackingCompanyURLs[selectionOptionIndex];
            var newTrackUrl = tempTrackUrl.replace("<tracking_number>",lineItems[index].trackingCode);
            lineItems[index].trackingUrl = newTrackUrl
        }
        
        this.setState({ lineItems });
    };
    
   /*old code 
   updateTrackingUrl = (e, value,index) => {
        const lineItems = [...this.state.line_items];
        lineItems[index].trackingUrl = value
        this.setState({ lineItems });
    }*/

    render() {

        return (
            <div className="container-fluid">
                <div className="row row-eq-height">
                    <div className="col-sm-12 content">
                        <NarrowContainer>
                            {  this.state.fraudRisk ==='cancel' &&
                                <div>
                                    <Link className="back" to='/fraud'><i className="fas fa-chevron-left"></i>Back</Link>
                                </div>
                            }
                            {  this.state.fraudRisk !=='cancel' &&
                                <div>
                                    <Link className="back" to={{pathname: `/orders/`,state: {storeId: `${this.state.searchValues.storeId}`, keyword: `${this.state.searchValues.keyword}`, fromDate: `${this.state.searchValues.fromDate}`,toDate: `${this.state.searchValues.toDate}`,status:`${this.state.searchValues.status}`,page:`${this.state.searchValues.page}`}}}><i className="fas fa-chevron-left"></i>Back</Link>
                                </div>
                            }
                            <Title title={this.state.id === 'new' ? "New Order" : `Order #${this.state.order_number}`} />

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="container-box">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '15%' }}>SKU</th>
                                                    <th style={{ width: '65%' }}>Product Name</th>
                                                    <th style={{ width: '10%' }}>Price</th>
                                                    <th style={{ width: '10%' }}>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.line_items && this.state.line_items.length >0 ? this.state.line_items.map((item, index) =>
                                                    <tr>
                                                        <td>{item.sku}</td>
                                                        <td style={{ lineHeight: '17px', paddingTop: '5px', paddingBottom: '5px' }}><span title={item.title}>{item.title.length > 50 ? `${item.title.substring(0, 50)}...` : item.title}</span><br />
                                                            <small>{item.fulfilment}</small></td>
                                                        <td>&pound;{item.line_price.toFixed(2)}</td>
                                                        <td>{item.quantity}</td>
                                                    </tr>
                                                ):""}
                                                <tr>
                                                    <td colSpan={3} style={{ textAlign: 'right' }}><strong>Total Cost</strong></td>
                                                    <td><strong>&pound;{typeof(this.state.total_price)!=="undefined" ? this.state.total_price.toFixed(2):''}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="row">
                                            <div className="col-sm-6">
                                                {this.state.fraudRisk !== 'cancel' &&
                                                    <div>
                                                        <button onClick={() => this.sendMail("confirmation")} className="btn btn-secondary" style={{ margin: 5 }}>Resend Order Confirmation Email</button> {this.state.resent === 'confirmation' && `Sent!`}
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-sm-6 text-right">
                                                {(this.state.userLevel === 'admin' && this.state.pendingTransactions > 0) &&
                                                    <div>
                                                        <button onClick={this.toggleBlock} className="btn btn-secondary" style={{ margin: 5 }}>{this.state.blockPayment ? `Unblock Payments` : `Block Payments`}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <Divider />

                            {this.state.fraudRisk === 'cancel' &&
                                <FormContainer title="Fraud Risk"
                                    description={``}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <p style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>This order has been flagged by Shopify as a potential High Risk of Fraud.</p>
                                            <p style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>Please review the order information and either approve to pass the order to the store, or reject this order.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" style={{ textAlign: 'center' }}>
                                            <button onClick={this.approve} className="btn" style={{ margin: 5 }}>APPROVE ORDER</button>
                                            <button onClick={this.decline} className="btn" style={{ margin: 5 }}>REJECT ORDER</button>
                                        </div>
                                    </div>
                                </FormContainer>
                            }

                            {this.state.fraudRisk === 'cancel' &&
                                <Divider />
                            }

                            <FormContainer title="Customer Information"
                                description={``}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="customerFirstName"
                                            type="text"
                                            label="First Name"
                                            value={this.state.customerFirstName}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="customerLastName"
                                            type="text"
                                            label="Last Name"
                                            value={this.state.customerLastName}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="email"
                                            type="text"
                                            label="Email Address"
                                            value={this.state.email}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="telephone"
                                            type="text"
                                            label="Telephone Number"
                                            value={this.state.telephone}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                            </FormContainer>

                            <Divider />

                            <FormContainer title="Delivery Information"
                                description={``}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="shippingFirstName"
                                            type="text"
                                            label="First Name"
                                            value={this.state.shippingFirstName}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="shippingLastName"
                                            type="text"
                                            label="Last Name"
                                            value={this.state.shippingLastName}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="shippingLine1"
                                            type="text"
                                            label="Address (Line 1)"
                                            value={this.state.shippingLine1}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="shippingLine2"
                                            type="text"
                                            label="Address (Line 2)"
                                            value={this.state.shippingLine2}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="shippingTown"
                                            type="text"
                                            label="Town"
                                            value={this.state.shippingTown}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <TextInput
                                            name="shippingPostCode"
                                            type="text"
                                            label="Postal Cost"
                                            value={this.state.shippingPostCode}
                                            maxLength={150}
                                            disabled={true}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    </div>
                                </div>

                            </FormContainer>

                            <Divider />

                            <FormContainer title="Notes"
                                description={``}>

                                <Notes id={this.state.id} />

                            </FormContainer>

                            <Divider />

                            <FormContainer title={this.state.formTitle}
                                description={``}>
                                <div className="row">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>SKU</th>
                                                    <th>Fulfillment Status*</th>
                                                    <th>Tracking / Collection Code*</th>
                                                    <th>Tracking Company*</th>
                                                    <th>Carrier URL (UK Mail, DHL, DPD, Royal Mail)*</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.state.line_items && this.state.line_items.length> 0 ? 
                                                    this.state.line_items.map((item, index) => (
                                                    <tr>
                                                        <td>{item.sku}
                                                        <input type="hidden" value= {(item.fulfilmentOption === 'anyfulfilment')?item.fulfilmentOption = this.state.fulfilmentMethod: null}></input></td>
                                                        <td>{item.fulfilmentOption === 'delivery'  &&
                                                            <SelectBox
                                                            key={index}
                                                            name="fulfillment_status"
                                                            value={item.fulfillment_status}
                                                          //  disabled = {this.state.fulfillOptionDisable}
                                                            options={item.fulfillment_status == 'fulfilled'?this.state.selectFulfilledOptions:item.fulfillment_status=='refunded'?this.state.selectRefundOptions:item.fulfillment_status=='partial-refund'?this.state.selectPartialRefundOptions:this.state.selectPendingOptions}
                                                            asterix={true}
                                                            onChange={(e,val) => this.updateFulfillmentStatus(e,val,index)}
                                                            error={this.state.errFulfillmentStatus}
                                                        />
                                                        }{item.fulfilmentOption !== 'delivery'  &&
                                                            <SelectBox
                                                            key={index}
                                                            name="fulfillment_status"
                                                            value={item.fulfillment_status}
                                                          //  disabled = {this.state.fulfillOptionDisable}
                                                            options={item.fulfillment_status == 'collected'?this.state.selectCollectedOptions:item.fulfillment_status=='refunded'?this.state.selectRefundOptions:item.fulfillment_status=='partial-refund'?this.state.selectPartialRefundOptions:this.state.selectPendingCollectOptions}
                                                            asterix={true}
                                                            onChange={(e,val) => this.updateFulfillmentStatus(e,val,index)}
                                                            error={this.state.errFulfillmentStatus}
                                                        />
                                                        }
                                                        </td>
                                                        <td>  {item.fulfilmentOption === 'delivery'  &&
                                                        <TextInput
                                                            key={index}
                                                            name="trackingCode"
                                                            type="text"
                                                            value={item.trackingCode}
                                                            maxLength={150}
                                                            asterix={true}
                                                            onChange={(e,val) => this.updateTrackingCode(e,val,index)}
                                                            error={this.state.errorTrackingCodes[index]}
                                                        />}
                                                        { item.fulfilmentOption !== 'delivery'  &&
                                                            <TextInput
                                                            name="collectionCode"
                                                            type="text"
                                                            value={this.state.collectionCode}
                                                            maxLength={150}
                                                            disabled={true}
                                                            onChange={this.onChange.bind(this)}
                                                            />
                                                        }
                                                        </td>
                                                        <td>{item.fulfilmentOption === 'delivery'  &&
                                                            <SelectBox
                                                            key={index}
                                                            name="tracking_company"
                                                            value={item.trackingCompany}
                                                            options={this.state.selectTrackingCompanyOptions}
                                                            asterix={true}
                                                            onChange={(e,val) => this.updateTrackingUrl(e,val,index)}
                                                            error={this.state.errorTrackingCompanys[index]}
                                                            />
                                                        }
                                                        </td>
                                                        <td>
                                                        {item.fulfilmentOption === 'delivery'  &&
                                                            <TextInput
                                                                    key={index}
                                                                name="trackingUrl"
                                                                type="text"
                                                                value={item.trackingUrl}
                                                                placeholder="https://track.dhlparcel.co.uk/12345678"
                                                                maxLength={250}
                                                                asterix={true}
                                                                disabled={true}
                                                                onChange={(e,val) => this.updateTrackingUrl(e,val,index)}
                                                                error={this.state.errTrackingUrl}
                                                            />
                                                        }</td>
                                                        
                                                    </tr>
                                                )): ""}
                                            </tbody>
                                        </table>
                                    </div>
                                    {this.state.original_fulfillment_status == "ready" &&
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <button onClick={() => this.sendMail("collection")} className="btn btn-secondary" style={{ margin: 5 }}>Resend Collection Ready Email</button> {this.state.resent === 'collection' && `Sent!`}
                                            </div>
                                        </div>
                                    }
                            </FormContainer>
                            

                           
                            <Divider />

                            <div className="row">
                                <div className="col-sm-4">
                                    &nbsp;
                                </div>
                                <div className="col-sm-4" style={{ paddingBottom: 60 }}>
                                    &nbsp;
                                </div>
                                <div className="col-sm-4 text-right">
                                    <SubmitButton
                                        text="Update Order"
                                        className="btn"
                                        onClick={this.save}
                                    />
                                </div>
                            </div>

                        </NarrowContainer>
                    </div>
                </div>
            </div>
        )

    }

}

export default OrderPage;